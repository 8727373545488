import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import PhoneIcon from "@material-ui/icons/Phone";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import EmailIcon from "@material-ui/icons/Email";
import BusinessIcon from "@material-ui/icons/Business";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import logo from "../assets/jjs_waste_final_web_rgb.png";

// Use environment variables or other secure methods to manage API keys
const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const ANYDESK_WINDOWS_DOWNLOAD_URL = process.env.REACT_APP_ANYDESK_WINDOWS_DOWNLOAD_URL;
const MAP_LOC = `https://www.google.com/maps/embed/v1/place?key=${API_KEY}&q=JJ+Richards+Sons,Cleveland,QLD`;

console.log(ANYDESK_WINDOWS_DOWNLOAD_URL);

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.jjswaste.com.au/">
        JJs Waste & Recycling Pty Ltd
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  container: {
    padding: theme.spacing(4),
  },
  appbar: {
    alignItems: "center",
  },
  img: {
    width: "100%",
    height: "auto",
  },
}));

export default function Album() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar className={classes.appbar} position="relative">
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            JJs Waste & Recycling IT Support
          </Typography>
        </Toolbar>
      </AppBar>
      <Container className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
              <img className={classes.img} src={logo} alt="JJs Waste Logo" />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
              <List component="nav">
                <ListItemLink href="tel:+6173488965">
                  <ListItemIcon>
                    <PhoneIcon />
                  </ListItemIcon>
                  <ListItemText primary="+61 7 3488 9635" />
                </ListItemLink>
                <ListItemLink href="mailto:servicedesk@jjswaste.com.au">
                  <ListItemIcon>
                    <EmailIcon />
                  </ListItemIcon>
                  <ListItemText primary="servicedesk@jjswaste.com.au" />
                </ListItemLink>
                <ListItemLink
                  href="https://maps.google.com/maps?ll=-27.525232,153.252647&z=15&t=m&hl=en-US&gl=US&mapclient=embed&cid=111981598961952257"
                  target="_blank"
                >
                  <ListItemIcon>
                    <BusinessIcon />
                  </ListItemIcon>
                  <ListItemText primary="1/3 Grant Street, Cleveland, QLD, Australia, 4163" />
                </ListItemLink>
                <ListItemLink
                  href="https://www.jjrichards.com.au/notice/"
                  target="_blank"
                >
                  <ListItemIcon>
                    <LiveHelpIcon />
                  </ListItemIcon>
                  <ListItemText primary="Work from home information" />
                </ListItemLink>
              </List>
              <iframe
                title="Address"
                width="100%"
                height="180px"
                src={MAP_LOC}
                allowFullScreen
                style={{ border: 0 }}
              ></iframe>
            </Paper>
          </Grid>
          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleClickOpen}
              >
                Download Remote Support Software
              </Button>
            </Grid>
          </Grid>
          <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}
          >
            <DialogTitle id="simple-dialog-title">Choose an option</DialogTitle>
            <Grid item spacing={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClose}
                  href={ANYDESK_WINDOWS_DOWNLOAD_URL}
                  alt="Download AnyDesk for Windows"
                  className={classes.button}
                >
                  Windows
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClose}
                  href="https://get.anydesk.com/RGc81AbU/AnyDesk_macOS_Client.dmg"
                  alt="Download AnyDesk for macOS"
                  className={classes.button}
                >
                  macOS
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClose}
                  href="https://play.google.com/store/apps/details?id=com.anydesk.anydeskandroid&hl=en_AU"
                  alt="Download AnyDesk for Android"
                  className={classes.button}
                >
                  Android
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClose}
                  href="https://apps.apple.com/us/app/anydesk/id1176131273"
                  alt="Download AnyDesk for iOS"
                  className={classes.button}
                >
                  iOS
                </Button>
            </Grid>
          </Dialog>
        </Grid>
      </Container>
      <footer className={classes.footer}>
        <Copyright />
      </footer>
    </React.Fragment>
  );
}