import React from "react";
import "./App.css";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import SupportPage from "./components/SupportPage";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#4caf50",
      contrastText: "#fff",
    },
  },
});

function App() {
  return (
    <>
      <MuiThemeProvider theme={theme}>
        <SupportPage />
      </MuiThemeProvider>
    </>
  );
}

export default App;
